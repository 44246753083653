<template>
  <v-card
    elevation="4"
    class="data-table"
    :class="canBeSelected ? 'data-table--selectable' : ''"
  >
    <v-data-table
      calculate-widths
      :headers="headers"
      :items="itemsLoading ? [] : items"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="searchTerm"
      :sort-by="sortBy.value"
      :sort-desc="sortDesc"
      hide-default-footer
      hide-default-header
      class="elevation-0 custom-data-table"
      :server-items-length="itemsTotal"
    >
      <template v-slot:body v-if="itemsLoading">
        <template v-if="$vuetify.breakpoint.lgAndDown">
          <config-table-item-skeleton-mobile v-for="item in 5" :key="item" />
        </template>
        <template v-else>
          <config-table-skeleton-sort />
          <config-table-skeleton-item />
        </template>
      </template>

      <template v-slot:no-data>
        <template v-if="!itemsLoading && !searchTerm">
          <TableFullScreenMessage :title="$t('heading.instance.advanced.users.noData')">
            <template v-slot:image>
              <pluginsIllustration/>
            </template>
          </TableFullScreenMessage>
        </template>
        <data-iterator-no-results-container
          v-if="searchTerm"
          @clearAllFilters="$emit('update:searchTerm', '')"
          :searchTerm="searchTerm"
        />
      </template>

      <template
        v-if="$vuetify.breakpoint.width >= 1400 && !itemsLoading"
        v-slot:header="{ isMobile, props, on }"
      >
        <table-custom-sort
          :isMobile="isMobile"
          :props="props"
          :v-on="on"
          v-on="$listeners"
        />
      </template>

      <template v-slot:item.user_registered="{ item }">
        {{ item.user_registered_text }}
      </template>

      <template v-slot:item.role="{ item }">
        <UsersRoleLabel :role="item.role" />
      </template>

      <template v-slot:item.email="{ item }">
        <a
          :href="`mailto:${item.email}`"
          class="d-flex align-center primary-on-hover base-text"
        >
          <v-avatar
            :min-width="28"
            :width="28"
            :height="28"
            large
            color="white"
            class="mr-2"
          >
            <v-img
              :width="28"
              :height="28"
              :src="getAvatarUrl(item.email, 28)"
            />
          </v-avatar>
          <span class="primary-on-hover base--text">
            {{ item.email }}
            <v-icon size="16" class="show-on-hover" color="gray lighten-1">
              $arrowCornerRightIcon
            </v-icon>
          </span>
        </a>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex actions-row">
          <v-tooltip
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                small
                @click="$emit('action-button-edit', item)"
              >
                <v-icon>$edit</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('button.user.edit') }}</span>
          </v-tooltip>
          <v-tooltip
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                small
                @click="$emit('action-button-reset-password', item)"
              >
                <v-icon>$restore</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('button.resetPassword') }}</span>
          </v-tooltip>
          <v-tooltip
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                small
                :disabled="item.id === 1"
                @click="$emit('action-button-delete', item)"
              >
                <v-icon>$thrash</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('button.user.delete') }}</span>
          </v-tooltip>
        </div>
      </template>

      <template
        v-if="$vuetify.breakpoint.width < 1400"
        v-slot:item="{ item, headers }"
      >
        <tr>
          <td class="w-100 px-0">
            <div class="mobile-table-item">
              <div class="mobile-table-item__values">
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[0])"
                  >
                    {{ $t('table.header.id') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.id }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[1])"
                  >
                    {{ $t('table.header.login') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.username }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[2])"
                  >
                    {{ $t('table.header.displayName') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.display_name }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[3])"
                  >
                    {{ $t('table.header.email') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.email }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[4])"
                  >
                    {{ $t('table.header.role') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.role }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[5])"
                  >
                    {{ $t('table.header.registered') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.user_registered_text }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__actions">
                  <v-tooltip
                    transition="custom-tooltip"
                    open-delay="150"
                    bottom
                    z-index="99"
                    offset-overflow
                    nudge-bottom="4px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        icon
                        small
                        @click="$emit('action-button-edit', item)"
                      >
                        <v-icon>$edit2</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('button.user.edit') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    transition="custom-tooltip"
                    open-delay="150"
                    bottom
                    z-index="99"
                    offset-overflow
                    nudge-bottom="4px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        icon
                        small
                        @click="$emit('action-button-reset-password', item)"
                      >
                        <v-icon>$restore</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('button.resetPassword') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    transition="custom-tooltip"
                    open-delay="150"
                    bottom
                    z-index="99"
                    offset-overflow
                    nudge-bottom="4px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        icon
                        small
                        @click="$emit('action-button-delete', item)"
                      >
                        <v-icon>$thrash</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('button.user.delete') }}</span>
                  </v-tooltip>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template v-slot:footer>
        <div>
          <data-iterator-footer
            :page="page"
            :numberOfPages="numberOfPages"
            :possibleItemsPerPage="possibleItemsPerPage"
            :listStyle="listStyle"
            :itemsPerPage="itemsPerPage"
            :itemsTotal="itemsTotal"
            v-on="$listeners"
            :perPageLabel="$t('table.footer.user')"
          >
          </data-iterator-footer>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { getAvatarUrl } from "@/apis/HelperFunctions";

import DataIteratorMixin from "../../../mixins/DataIteratorMixin";
import CustomTableMixin from "../../../mixins/CustomTableMixin";

import DataIteratorFooter from "../DataIteratorFooter.vue";
import TableCustomSort from "../TableCustomSort.vue";

import ConfigTableSkeletonItem from "./ConfigTableSkeletonItem.vue";
import ConfigTableSkeletonSort from "./ConfigTableSkeletonSort.vue";
import ConfigTableItemSkeletonMobile from "./ConfigTableItemSkeletonMobile.vue";
import UsersRoleLabel from "../../labels/UserRoleLabel.vue";
import TableFullScreenMessage from "../../dataIterator/tables/TableFullScreenMessage.vue";
import DataIteratorNoResultsContainer from "../DataIteratorNoResultsContainer.vue";
import pluginsIllustration from "../../illustrations/plugins-illustration.vue";

export default {
  components: {
    DataIteratorFooter,
    TableCustomSort,
    ConfigTableSkeletonItem,
    ConfigTableSkeletonSort,
    ConfigTableItemSkeletonMobile,
    UsersRoleLabel,
    TableFullScreenMessage,
    DataIteratorNoResultsContainer,
    pluginsIllustration,
  },
  data: function () {
    return {
      canBeSelected: false,
    };
  },
  mixins: [DataIteratorMixin, CustomTableMixin],
  props: {
    searchTerm: String,
    headers: Array,
    itemsTotal: Number,
    itemsLoading: Boolean,
  },
  methods: {
    hideSecret: function (secret) {
      return "•".repeat(secret.length + 1);
    },
    getAvatarUrl: function (email) {
      return getAvatarUrl(email);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  margin-left: 10px;
  @media (max-width: 1183px) {
    margin-left: 0px;
    margin-right: 10px;
  }
}
.v-data-table::v-deep {
  td {
    color: var(--v-gray-darken2);
  }
}
.v-avatar {
  border-color: var(--v-gray-lighten2) !important;
  border-style: solid !important;
  border-width: 1px !important;
}
</style>
